import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from "../services/app.service";

@Injectable({
    providedIn: 'root'
})

export class LoginGuard implements CanActivate {
    constructor(private router: Router, private appService: AppService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!localStorage.getItem('token')) {
            return true
        }
        this.router.navigate(['/admin'])
        return false;
    }

}