import { Group } from "./groups.model";
import { promoGroupMembers } from "./promoGroupMember.model";

export class User{
    id?:number;
    strEmailId?:string;
    strAuthorization?:string;
    localGroupId?:number;
    token?:string;
    name?:string;
    email?:string;
    phone?:string;
    countryCode?:string;
    status?:string;
    password?:string;
    regionId?:string;
    role?:string;
    imgUrl?:any;
    userType?:string;
    username?:string
    dob?:any
    createdAt?:any
    groupType?:string
    promoGroupMembers?: promoGroupMembers[];
    group?:Group;
    
}